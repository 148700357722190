<template>
  <div>
    <p class="compont_p">会员基本信息-资料审核通过后只能进行密码修改</p>
    <div class="form_wrap">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-form-item label="会员账号：" prop="username">
          <el-input v-model="form.name" :disabled="disable" placeholder="请输入会员账号"></el-input>
        </el-form-item>
        <el-form-item label="会员类型：" prop="jylx">
          <el-select v-model="form.jylx" placeholder="请选择会员类型" :disabled="disable">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员密码：" prop="userpass">
          <el-input v-model="form.userpass" type="password" placeholder="请输入会员密码"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn flex_c_c" @click="handleSave">修改密码</div>
        </el-form-item>
      </el-form>
    </div>
    <p class="compont_p">采购人基本信息</p>
    <div class="form_wrap">
      <el-form label-width="100px" :model="form">
        <el-form-item label="采购人姓名：">
          <el-input v-model="form.cname" :disabled="disable" placeholder="请输入采购人姓名"></el-input>
        </el-form-item>
        <el-form-item label="采购人手机：">
          <el-input v-model="form.phone" :disabled="disable" placeholder="请输入采购人手机"></el-input>
        </el-form-item>
        <!-- <el-form-item label="采购人邮箱：">
          <el-input v-model="form.lxyx" :disabled="disable" placeholder="请输入采购人邮箱"></el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <p class="compont_p">单位基本信息</p>
    <div class="form_wrap">
      <el-form label-width="100px" :model="form">
        <el-form-item label="单位名称：">
          <el-input v-model="form.dwmc" :disabled="disable" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="经营内容：">
          <el-tag style="margin-right: 8px" v-for="item in form.scope" :key="item.name">{{ item.name }}</el-tag>
        </el-form-item>
        <el-form-item label="详细地址：">
          <div class="flex_row">
            <el-cascader class="mr_10" :disabled="disable" v-model="value" @change="handleChange" :options="options1" :props="{ expandTrigger: 'hover', label: 'text' }"></el-cascader>
            <el-input :disabled="disable" v-model="form.organization_address" placeholder="请输入详细地址"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <p style="color: #ff4c4c">* 审核通过后不能进行资料修改，只允许修改密码</p>
        </el-form-item>
        <!-- <el-form-item>
          <div class="btn flex_c_c" @click="handleSave">提交资料</div>
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import md5 from "md5";

export default {
  data() {
    return {
      form: {
        cname: "",
        userpass: "",
        jylx: "",
        lxr: "",
        phone: "",
        lxyx: "",
        dwmc: "",
        scope: [],
        sheng: "",
        shi: "",
        quxian: "",
        organization_address: "",
      },
      disable: true, //审核状态 1允许修改，>1禁止修改
      checkAll: false,
      isIndeterminate: true,
      options: [],
      value: "",
      // checkList: [],
      options1: [],
      rules: {
        cname: [{ required: true, message: "请输入会员账号", trigger: "blur" }],
        // jylx: [{ required: true, message: "请选择会员类型", trigger: "change" }],
        // userpass: [{ required: true, message: "请输入会员密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$api("account.getInfo").then((res) => {
      this.value = res.data.areas;
      console.log("省市区信息---", this.value);
      this.form = res.data;
    });
    this.$api("login.getCity").then((res) => {
      this.options1 = res.data;
    });
    this.$api("login.getType").then((res) => {
      this.options = res.data;
    });
  },
  methods: {
    handleChange(e) {
      console.log("e---", e);
    },
    handleSave() {
      // console.log(this.form);
      let p = {
        cname: this.form.cname,
        phone: this.form.phone,
        areas: this.value,
        password: md5(this.form.userpass),
      };
      // console.log("提交信息---", p);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api("account.updateInfo", p).then(() => {
            this.$message.success("提交资料成功");
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 200px;
}
.form_wrap {
  margin-left: 154px;
  margin-top: 20px;
}
.btn {
  width: 76px;
  height: 30px;
  background: @themeColor;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
}
</style>
